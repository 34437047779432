<template>
    <div class="mainWrapper pb-0">
        <section class="blockElement space flexCol fiveColumn position-relative newMarketLoop pt-4">
            <!-- v-if="!store.loading" -->
            <div class="container-fluid">
                <div class="row mb-3">
                    <div class="col-12 mb-3">
                        <div class="d-flex align-items-start mb-2 line-1">
                            <v-lazy-image class="me-2 mt-1" src="/assets/images/maktTrade.png" alt="IconArrow" />
                            <div class="leftBlock">
                                <h1 class="d-flex align-items-center medium f-30 mb-2 line-1">
                                    {{ $t("marketList.text1") }}
                                </h1>
                                <p class="mb-0">{{ $t("marketList.text22") }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4" >
                        <div class="bg-white border br-18 p-0 mb-4">
                            <div class="addBG overflow-auto megaDropMenu newFillTabs p-3">
                                <a href="javascript:void(0)"
                                    class="button fillBtn zulu_btn mobButoon d-inline-flex align-items-center justify-content-between d-md-none"
                                    @click="addActive = !addActive">
                                    {{
                                    tab == 1
                                    ? $t("marketList.text11")
                                    : tab == 3
                                    ? $t("marketList.text7")
                                    : newsObj[tab]
                                    }}
                                    <vue-feather size="18" type="chevron-down" class="ms-3"></vue-feather>
                                </a>
                                <ul id="tabs-nav" class="zuluTabs d-flex align-items-center px-0 mb-1" :class="[{ active: addActive }]">
                                    <li v-if="store.user.access_token" :class="[{ active: tab == 1 }]">
                                        <a class="f-15" href="javascript:void(0)" @click="changeTab(1);addActive = false;">{{ $t("marketList.text11") }}</a>
                                    </li>
                                    <li :class="[{ active: tab == 2 }]">
                                        <a class="f-15" href="javascript:void(0)" @click="changeTab(2);addActive = false;">{{ $t("marketList.text5") }}</a>
                                    </li>
                                    <li :class="[{ active: tab == 3 }]">
                                        <a class="f-15" href="javascript:void(0)" @click="changeTab(3);addActive = false;">{{ $t("marketList.text7") }}</a>
                                    </li>
                                    <li :class="[{ active: tab == 4 }]">
                                        <a class="f-15" href="javascript:void(0)" @click="changeTab(4);addActive = false;">{{ $t("marketList.text10") }}</a>
                                    </li>
                                    <li :class="[{ active: tab == 5 }]">
                                        <a class="f-15" href="javascript:void(0)" @click="changeTab(5);addActive = false;">{{ $t("marketList.text8") }}</a>
                                    </li>
                                    <li :class="[{ active: tab == 6 }]">
                                        <a class="f-15" href="javascript:void(0)" @click="changeTab(6);addActive = false;">{{ $t("marketList.text9") }}</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="showCriptoTabs overflow-auto px-md-3" :style="{'height':calcHeight-80 + 'px'}">
                                <div class="row mx-0 g-0" v-if="tab == '1' && store.user.access_token">
                                    <div class="col-12">
                                        <WatchlistWidget :watchListType="'NEW_MARKET'"></WatchlistWidget>
                                    </div>
                                </div>
                                <div class="row mx-0 g-0" v-if="tab == '2' || tab == '3' || tab == '4' || tab == '5' || tab == '6'">
                                    <div class="col-12 overflow-auto ">
                                        <ul class="loopList" :class="{'stocksTab': tab == '3'}">
                                            <li :class="[{'active':value.id==this.marketSelected.id}]" v-for="(value, key) in store.categoryWiseMarket?.marketsByCategory?.[this.assetsObj[this.tab]]" :key="key">
                                                <div class="customTradeAdd shadow-none w-100" v-if="Object.keys(value).length">
                                                    <div class="card-body px-0 py-2 text-center position-relative flex-between pointer pricePercentage" @click="getSetID(value);addClassInBody()">
                                                        <div class="vueRisk d-flex align-items-center">
                                                            <span class="mb-0 d-flex defineCircle me-2">
                                                                <span class="d-inline-flex" v-if="value.symbol">
                                                                    <v-lazy-image class="euCurrency" :src="
                                                                    static_vars.marketImageSURL +
                                                                    value.symbol.toUpperCase().replace(/\//g, '') +
                                                                    '.svg'
                                                                    " :alt="value.symbol.toString().split('/')[0].toLowerCase()"
                                                                                :title="
                                                                    value.symbol.toString().split('/')[0].toLowerCase()
                                                                    " @error="handleImgErr($event)" />
                                                                </span>
                                                            </span>
                                                            <span class="mb-0 d-block">
                                                                <h6 class="mb-0 medium neutralGrey f-15" :title="value.symbol">{{ value.symbol
                                                                    }}</h6>
                                                            </span>
                                                        </div>
                                                        <div class="title mb-0">

                                                            <div class="element">
                                                                <div class="elementHub">
                                                                    <p class="mb-0 p-0 neutralGrey f-15" >
                                                                        <span class="arw position-absolute" :class="
                                                                        parseFloat(value.dailyPriceChangePercentage || value.lastChangePercentage) >= 0.0
                                                                        ? 'green'
                                                                        : 'red'
                                                                        ">
                                                                        <i :class="parseFloat(value.dailyPriceChangePercentage || value.lastChangePercentage) > 0.0 ? 'fa fa-caret-up me-1' : 'fa fa-caret-down me-1'"
                                                                            aria-hidden="true"></i>
                                                                        </span>
                                                    
                                                                        {{ COMMON.livePriceSymbolList(value.symbol,'buy',value.currentPrice)
                                                                        }}
                                                                    </p>
                                                                    <p class="mb-0 py-0 d-inline-flex align-items-center f-14" :class="
                                                                    parseFloat(value.dailyPriceChangePercentage || value.lastChangePercentage) >= 0.0
                                                                    ? 'green'
                                                                    : 'red'
                                                                    ">
                                                                            {{ parseFloat(value.dailyPriceChangePercentage || value.lastChangePercentage) > 0.0 ? "+" : ""}}{{ parseFloat(value.dailyPriceChangePercentage || value.lastChangePercentage) ? parseFloat(value.dailyPriceChangePercentage || value.lastChangePercentage).toFixed(2) : 0 }}%
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="abTrade">
                                                            <div
                                                                class="d-flex align-items-center justify-content-center tradeType">
                                                                <a href="javascript:void(0)"
                                                                    class="button buyBtn fillBtn zulu_btn me-1"
                                                                    @click="instrumentDetail.symbol = value.symbol;
                                                                    instrumentDetail.dailyPriceChangePercentage =
                                                                    value.lastChangePercentage;instrumentDetail.currentPrice = value.currentPrice;
                                                                    store.user.access_token
                                                                    ? (tradeType = 'buy')
                                                                    : (showLoginPopup = true);
                                                                    " :class="{ 'disabled': store.customerDetail?.readOnly }">
                                                                    {{ $t("home.text78") }}</a>
                                                                <a href="javascript:void(0)"
                                                                    class="button sellBtn fillBtn zulu_btn" @click="
                                                                    instrumentDetail.symbol = value.symbol;
                                                                    instrumentDetail.dailyPriceChangePercentage =
                                                                    value.lastChangePercentage;instrumentDetail.currentPrice = value.currentPrice;store.user.access_token
                                                                    ? (tradeType = 'sell')
                                                                    : (showLoginPopup = true);
                                                                    " :class="{ 'disabled': store.customerDetail?.readOnly }">
                                                                    {{ $t("home.text79") }}</a>
                                                            </div>
                                                            <a class="star inLarge" href="javascript:void(0)"
                                                                @click.stop="AddWatchList(value)"
                                                                :class="{ 'disabled': store.customerDetail?.readOnly }">
                                                                <vue-feather type="star" size="19"
                                                                    :class="[{ filled: isFollowed(value.id) }]"></vue-feather>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-12">
                                        <Nodata v-if="!store.categoryWiseMarket?.marketsByCategory?.[this.assetsObj[this.tab]]?.length"></Nodata>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-8" ref="scrollToDetail">
                        <MarketDetail :marketSelectedProp="marketSelected"/>
                    </div>

                    <div class="col-12 mt-md-5 mt-4 d-none">
                        <div class="row mb-4 pb-md-4">
                            <div class="col-12 col-xl-5 col-xxl-5 d-flex align-items-center mb-3 mb-xl-0">
                                <div class="card-body vueCard element"
                                    v-if="store.categoryWiseMarket?.mostPopular?.length">
                                    <div class="card-header bg-white flex-between">
                                        <p class="f-20 medium mb-0 d-flex align-items-center">
                                            <v-lazy-image class="me-3" src="/assets/images/hotFire.png"
                                                alt="IconArrow" />
                                            {{ $t("marketList.text4") }}
                                        </p>
                                        <router-link :to="{ name: 'all_market', params: { currency: 'popular' } }"
                                            class="roundArrow" href="javascript:void(0)">
                                            <vue-feather type="chevron-right"></vue-feather>
                                        </router-link>
                                    </div>
                                    <div class="flex-between px-3 mb-3" v-for="(value, key) in store.categoryWiseMarket?.mostPopular.slice(0, 5)" :key="key">
                                        <div class="d-flex align-items-center vueRisk">
                                            <span class="me-2 d-flex align-items-center">
                                                <router-link :to="{
                                        name: 'market_details',
                                        params: {
                                        id: value.id,
                                        currency: value.symbol.includes('/')
                                        ? value.symbol.split('/').join('-')
                                        : value.symbol,
                                        },
                                        }">
                                                    <v-lazy-image width="35" height="35" class="euCurrency" :src="
                                           static_vars.marketImageSURL +
                                           value.symbol.toUpperCase().replace(/\//g, '') +
                                           '.svg'
                                           " alt="ZuluTrade" title="Zulu User" @error="handleImgErr($event)" />
                                                </router-link>
                                            </span>
                                            <div class="flex-shrink-0">
                                                <router-link :to="{
                                        name: 'market_details',
                                        params: {
                                        id: value.id,
                                        currency: value.symbol.includes('/')
                                        ? value.symbol.split('/').join('-')
                                        : value.symbol,
                                        },
                                        }">
                                                    <p class="medium f-14 mb-0">{{ value.symbol || "" }}</p>
                                                </router-link>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <div :id="'mostPopular' + value.id" style="width: 80px; height: 30px"
                                                v-show="value.hourlyChart.length" class="rates-graph"></div>
                                            <p class="mb-0 bold mx-2 maxValueAf">
                                                {{
                                                value.symbol in store.allPrices
                                                ? store.allPrices[value.symbol].buyPrice
                                                : value.currentPrice
                                                }}
                                            </p>
                                            <p class="mb-0 py-0 px-1 medium d-inline-flex" :class="
                                     parseFloat(value.lastChangePercentage) >= 0.0
                                     ? 'green'
                                     : 'red'
                                     ">
                                                {{ parseFloat(value.lastChangePercentage) >= 0.0 ? "+" : ""
                                                }}{{ parseFloat(value.lastChangePercentage).toFixed(2) || 0 }}%
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-xl-7 col-xxl-7">
                                <div class="row">
                                    <!-- <div class="col-12 d-flex align-items-center mb-1 mb-lg-5">
                               <h5 class="d-flex align-items-center semibold mb-0 me-3">
                                   Market News
                               </h5>
                               <a href="javascript:void(0)" class="viewAll gray medium f-14 d-flex align-items-center"> View All <vue-feather class="ms-1" type="chevron-right" size="16"></vue-feather> </a>
                               </div> -->
                                    <div class="col-12 col-md-6" v-if="Object.keys(store.topWinnersListRates).length">
                                        <div class="card-body vueCard element">
                                            <div class="card-header flex-between mb-3">
                                                <p class="f-20 medium mb-0 d-flex align-items-center text-white">
                                                    <v-lazy-image class="me-2"
                                                        src="/assets/images/bxs_right-top-arrow-circle.png"
                                                        alt="IconArrow" />
                                                    {{ $t("marketList.text2") }}
                                                </p>
                                                <router-link
                                                    :to="{ name: 'all_market', params: { currency: 'winners' } }"
                                                    class="roundArrow" href="javascript:void(0)">
                                                    <vue-feather type="chevron-right"></vue-feather>
                                                </router-link>
                                            </div>
                                            <div class="flex-between px-2 mb-3" v-for="(value, key) in Object.values(
                                     store.topWinnersListRates
                                     ).slice(0, 5)" :key="key">
                                                <div class="d-flex align-items-center vueRisk">
                                                    <span class="d-flex align-items-center me-2">
                                                        <router-link :to="{
                                              name: 'market_details',
                                              params: {
                                              id: value.id,
                                              currency: value.symbol.includes('/')
                                              ? value.symbol.split('/').join('-')
                                              : value.symbol,
                                              },
                                              }">
                                                            <v-lazy-image width="30" height="30" class="euCurrency"
                                                                :src="
                                                 static_vars.marketImageSURL +
                                                 value.symbol.toUpperCase().replace(/\//g, '') +
                                                 '.svg'
                                                 " :alt="value.symbol.toString().split('/')[0].toLowerCase()" :title="
                                                 value.symbol.toString().split('/')[0].toLowerCase()
                                                 " @error="handleImgErr($event)" />
                                                        </router-link>
                                                    </span>
                                                    <div class="flex-shrink-0">
                                                        <router-link :to="{
                                              name: 'market_details',
                                              params: {
                                              id: value.id,
                                              currency: value.symbol.includes('/')
                                              ? value.symbol.split('/').join('-')
                                              : value.symbol,
                                              },
                                              }">
                                                            <p class="medium f-14 mb-0">{{ value.symbol || "" }}</p>
                                                        </router-link>
                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center">
                                                    <div :id="'topwinner' + value.id" style="width: 80px; height: 30px"
                                                        v-show="value.hourlyChart.length" class="rates-graph"></div>
                                                    <p class="mb-0 bold mx-2 maxValueAf">
                                                        {{
                                                        value.symbol in store.allPrices
                                                        ? store.allPrices[value.symbol].buyPrice
                                                        : value.currentPrice
                                                        }}
                                                    </p>
                                                    <p class="mb-0 py-0 px-1 medium d-inline-flex" :class="
                                           parseFloat(value.lastChangePercentage) >= 0.0
                                           ? 'green'
                                           : 'red'
                                           ">
                                                        {{ parseFloat(value.lastChangePercentage) >= 0.0 ? "+" : ""
                                                        }}{{
                                                        parseFloat(value.lastChangePercentage).toFixed(2) || 0
                                                        }}%
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6" v-if="Object.keys(store.topLoosersListRates).length">
                                        <div class="card-body vueCard element">
                                            <div class="card-header flex-between mb-3">
                                                <p class="f-20 medium mb-0 d-flex align-items-center text-white">
                                                    <v-lazy-image class="me-2" src="/assets/images/toplooswer.png"
                                                        alt="IconArrow" />
                                                    {{ $t("marketList.text3") }}
                                                </p>
                                                <router-link
                                                    :to="{ name: 'all_market', params: { currency: 'loosers' } }"
                                                    class="roundArrow" href="javascript:void(0)">
                                                    <vue-feather type="chevron-right"></vue-feather>
                                                </router-link>
                                            </div>
                                            <div class="flex-between px-2 mb-3" v-for="(value, key) in Object.values(
                                     store.topLoosersListRates
                                     ).slice(0, 5)" :key="key">
                                                <div class="d-flex align-items-center vueRisk">
                                                    <span class="me-2 d-flex align-items-center">
                                                        <router-link :to="{
                                              name: 'market_details',
                                              params: {
                                              id: value.id,
                                              currency: value.symbol.includes('/')
                                              ? value.symbol.split('/').join('-')
                                              : value.symbol,
                                              },
                                              }">
                                                            <v-lazy-image width="30" height="30" class="euCurrency"
                                                                :src="
                                                 static_vars.marketImageSURL +
                                                 value.symbol.toUpperCase().replace(/\//g, '') +
                                                 '.svg'
                                                 " :alt="value.symbol.toString().split('/')[0].toLowerCase()" :title="
                                                 value.symbol.toString().split('/')[0].toLowerCase()
                                                 " @error="handleImgErr($event)" />
                                                        </router-link>
                                                    </span>
                                                    <div class="flex-shrink-0">
                                                        <router-link :to="{
                                              name: 'market_details',
                                              params: {
                                              id: value.id,
                                              currency: value.symbol.includes('/')
                                              ? value.symbol.split('/').join('-')
                                              : value.symbol,
                                              },
                                              }">
                                                            <p class="medium f-14 mb-0">{{ value.symbol || "" }}</p>
                                                        </router-link>
                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center">
                                                    <div :id="'topLoosers' + value.id" style="width: 80px; height: 30px"
                                                        v-show="value.hourlyChart.length" class="rates-graph"></div>
                                                    <p class="mb-0 bold mx-2 maxValueAf">
                                                        {{
                                                        value.symbol in store.allPrices
                                                        ? store.allPrices[value.symbol].buyPrice
                                                        : value.currentPrice
                                                        }}
                                                    </p>
                                                    <p class="mb-0 py-0 px-1 medium d-inline-flex" :class="
                                           parseFloat(value.lastChangePercentage) >= 0.0
                                           ? 'green'
                                           : 'red'
                                           ">
                                                        {{ parseFloat(value.lastChangePercentage) >= 0.0 ? "+" : ""
                                                        }}{{
                                                        parseFloat(value.lastChangePercentage).toFixed(2) || 0
                                                        }}%
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <BuySell v-if="tradeType" />
    <loginPopup v-if="showLoginPopup"></loginPopup>
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    import moment from "moment";
    import WatchlistWidget from "@/views/trader/watchlist/watchlist-widget.vue";
    import BuySell from "@/views/dashboard-new/buy-sell";
    import MarketDetail from "@/views/markets/market_details.vue";
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
        data() {
            return {
                tradeType: "",
                instrumentDetail: { symbol: "", dailyPriceChangePercentage: 0, currentPrice: 0 },
                loaderNews: false,
                addActive: false,
                showNews: {},
                tab: 1,
                errImage: [],
                showLoginPopup: false,
                page: 1,
                perPage: 6,
                newsObj: {
                    1: "All",
                    2: this.$t("marketList.text5"),
                    3: "All",
                    4: this.$t("marketList.text16"),
                    5: this.$t("marketList.text8"),
                    6: this.$t("marketList.text9"),
                },
                marketSelected : {},
                assetsObj: {
                    1: '',
                    2: "1",
                    3: "8",
                    4: "4",
                    5: "7",
                    6: "3",
                },
                calcHeight: '',
            };
        },
        watch: {
            "$route.query"(){
                if(this.$route.query?.insID){
                    this.getCurrecnyDetail()
                    this.$router.replace({'query':null})
                }
            },
            "store.categoryWiseMarket"(){
                if(this.store.categoryWiseMarket?.marketsByCategory &&  Object.keys(this.store.categoryWiseMarket?.marketsByCategory).length){
                    if(Object.keys(this.$route.query).length) {
                        this.getCurrecnyDetail() 
                        this.$router.replace({'query':null})
                    } else {
                        if(!Object.keys(this.marketSelected).length){
                            this.marketSelected = Object.values(this.store.categoryWiseMarket?.marketsByCategory[Object.keys(this.store.categoryWiseMarket?.marketsByCategory)[0]])[0]
                        }
                    }
                }
            }
        },
        components: { WatchlistWidget, BuySell, MarketDetail },
        methods: {
            addClassInBody(){
                if(this.tradeType){
                    document.querySelector('body').classList.add("hideChatPage");
                }else{
                    document.querySelector('body').classList.remove("hideChatPage");
                }
            },
            getSetID(item){
                this.marketSelected = item
                let width = window.screen.width
                if(width < 992){
                    this.$refs.scrollToDetail.scrollIntoView({
                        offset: 84,
                        behavior: 'smooth'
                    })
                }
            },
            getCurrecnyDetail() {
                let formData = {
                    from: "",
                    to: "",
                    marketIds: [this.$route.query.insID],
                };
                formData["from"] = moment().subtract(1, "year").format("YYYY-MM-DD");
                formData["to"] = moment().format("YYYY-MM-DD");
                this.store.getAssetDetail(formData, true, this).then(() => {
                    if (!this.store.singleLoading && Object.keys(this.store.assetDetail).length) {
                        this.getSetID(this.store.assetDetail)
                    }
                });
            },
            showHideText(list) {
                if (Object.keys(list).length) {
                    if (Object.keys(this.showNews).length && list._id == this.showNews._id) {
                        if (list.source == "FXS" && list.text) {
                            return list.text;
                        } else if (list.description) {
                            return list.description;
                        } else {
                            if (list.source == "TE") {
                                return list.description;
                            } else {
                                return list.summary;
                            }
                        }
                    } else {
                        if (list.source == "FXS" && list.text) {
                            return `${list.text.substring(0, 50)}...`;
                        } else if (list.description) {
                            return `${list.description.substring(0, 50)}...`;
                        } else {
                            if (list.source == "TE" && list.text) {
                                return `${list.description.substring(0, 50)}...`;
                            } else if (list.text) {
                                return `${list.summary.substring(0, 50)}...`;
                            }
                        }
                    }
                } else {
                    return "";
                }
            },
            formatTime(date, format, offset) {
                if (!date) return "N/A";
                let d = moment(
                    `${date.split(" ")[0]} ${date.split(" ")[1]}`,
                    "YYYY-MM-DD HH:mm:ss"
                );
                let tz = offset != 0 ? offset : -new Date().getTimezoneOffset();
                d.add(tz, "minutes");
                return d.fromNow();
            },
            changeTab(val) {
                this.tab = val;
                // this.page = 1;
                // var payload = {
                //     page: this.page,
                //     perPage: this.perPage,
                // };
                // if (this.newsObj[val] != "All") {
                //     payload["category_type"] = this.newsObj[val];
                // }
                // this.store.getMarketNews(payload, true, this);
            },
            loadMore(val) {
                var payload = {
                    page: this.page + 1,
                    perPage: this.perPage,
                };
                if (this.newsObj[val] != "All") {
                    payload["category_type"] = this.newsObj[val];
                }
                this.store.getMarketNews(payload, true, this);
            },
            callWatchList() {
                this.store.getWatchList({}, false, "1");
            },
            AddWatchList(item) {
                if (!this.store.user.access_token) {
                    this.showLoginPopup = true;
                } else {
                    this.showLoginPopup = false;
                    if (this.isFollowed(item.id)) {
                        this.addWatchList(item, "DELETE");
                    } else {
                        this.addWatchList(item, "ADD");
                    }
                }
            },
            isFollowed(id) {
                if (this.store.watchList.length) {
                    let data = JSON.parse(JSON.stringify(this.store.watchList));
                    data = data.map((i) => i.id);
                    if (data.includes(parseInt(id))) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            },
            addWatchList(item, type) {
                let payload = {
                    item: item.id,
                    type: "MARKET",
                };
                let json = {};
                if (type == "ADD") {
                    json["id"] = item.id;
                    json["type"] = "MARKET";
                    json["currentPrice"] = item.currentPrice;
                    json["dailyPriceChangePercentage"] = item.dailyPriceChangePercentage;
                    json["description"] = item.description;
                    json["name"] = item.name;
                    json["chart"] = {};
                    let data = this.store.watchList;
                    data.push(json);
                    this.store.$patch({ watchList: data });
                } else if (type == "DELETE") {
                    let data = this.store.watchList.filter(
                        (i) => parseInt(i.id) != parseInt(item.id)
                    );
                    this.store.$patch({ watchList: data });
                }
                this.store.addToWatchList(payload, false, this, type);
            },
            handleImgErr(val) {
                var fullSrc = val.target.src.split(".");
                var src = fullSrc[fullSrc.length - 2].split("/");
                var img = src[src.length - 1];
                if (!this.errImage.includes(img)) {
                    this.errImage.push(img);
                }
            },
            isImage(img) {
                return this.errImage.includes(img) ? false : true;
            },
            getLastTwoDigit(price) {
                if (price) {
                    price = price.toString();
                    if (price.split(".")[1] && price.split(".")[1].length < 5) {
                        return "";
                    } else if (price.split(".")[1] && price.split(".")[1].length >= 5) {
                        return price
                            .split(".")[1]
                            .slice(price.split(".")[1].length - 2, price.split(".")[1].length);
                    }
                }
            },
            callCurrencyWithID() {
                this.store.getCurrencywithID({}, false);
            },
            allCategoryPrices() {
                let formData = {
                    from: moment().subtract(2, "week").format("YYYY-MM-DD"),
                    to: moment().format("YYYY-MM-DD"),
                    // limit: 100,
                    // page: 1,
                };

                let forexPayload = {
                    categories: [1],
                    ...formData,
                };
                let commoditiesPayload = {
                    categories: [3],
                    ...formData,
                };
                let cryptoPayload = {
                    categories: [4],
                    ...formData,
                };
                let indicesPayload = {
                    categories: [7],
                    ...formData,
                };
                let stocksPayload = {
                    categories: [8],
                    ...formData,
                };
                if (this.$route.name != "all_market") {
                    this.store.getRatePageCategories(forexPayload, false).then((res)=>{
                        if(res){ 
                            if(Object.keys(this.$route.query).length) {
                                this.getCurrecnyDetail() 
                                this.$router.replace({'query':null})
                            } else {
                                this.marketSelected = Object.values(res)[0]
                            }
                        }
                    })
                }
                if (this.$route.name != "all_market") {
                    this.store.getRatePageCategories(commoditiesPayload, false);
                }
                if (this.$route.name != "all_market") {
                    this.store.getRatePageCategories(cryptoPayload, false);
                }
                if (this.$route.name != "all_market") {
                    this.store.getRatePageCategories(indicesPayload, false);
                }
                if (this.$route.name != "all_market") {
                    this.store.getRatePageCategories(stocksPayload, false);
                }
            },
        },
        
        created() {
            if(this.store.categoryWiseMarket?.marketsByCategory &&  Object.keys(this.store.categoryWiseMarket?.marketsByCategory).length){
                if(Object.keys(this.$route.query).length) {
                    this.getCurrecnyDetail() 
                    this.$router.replace({'query':null})
                } else {
                    this.marketSelected = Object.values(this.store.categoryWiseMarket?.marketsByCategory[Object.keys(this.store.categoryWiseMarket?.marketsByCategory)[0]])[0]
                }
            }
            // this.allCategoryPrices();
            // this.callSortingCurrencies();
            this.callCurrencyWithID();
            // this.store.getMarketNews({ page: this.page, perPage: this.perPage }, true, this);
            if (this.store.user.access_token) {
                this.tab = 1;
            } else {
                this.tab = 2;
            }
        },
    };
</script>